@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;