/* input { 
  user-select: none;
}

.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.login_background {
  background: linear-gradient(0deg, #f9ae40,#b9bec0,#869dad);
}

.scroll-stable {
  scrollbar-gutter: stable;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader svg {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  0%, 75% { opacity: 1; }
  75%, 100% { opacity: 0; }
}

.loading-text .dot {
  animation: blink 3500ms infinite;
  display: inline-block;
  margin: 1px;
}
.loading-text .dot:nth-child(2) {
  animation-delay: 500ms; /* Adjust delay for second dot */
}

.loading-text .dot:nth-child(3) {
  animation-delay: 1000ms; /* Adjust delay for third dot */
}

.overlay_loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.overlay_loader {
  width: 70px;
  height: 70px;
  position: relative;
}

.overlay_loader:before {
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 6px solid #fb923c;
  position: absolute;
  top: 0;
  left: 0;
  animation: _pulse 1s ease-in-out infinite;
}

.overlay_loader:after {
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #fb923c;
  position: absolute;
  top: 0;
  left: 0;
  animation: spin 2s linear infinite;
}

.overlay_loader-text {
  font-size: 24px;
  margin-top: 20px;
  color: #fb923c;
  font-family: Arial, sans-serif;
  text-align: center;
}

@keyframes _pulse {
  0% {
    transform: scale(0.6);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(0.6);
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -200%;
  padding: 7px;
  min-width: 100px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}


/* width */
.thin-scrollbar::-webkit-scrollbar {
  height: 10px;
  padding-top: 2px;
}

/* Track */
.thin-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.gradient_background_animated {
  background: linear-gradient(300deg,#d5814a56,#7e95db7b);
  background-size: 120% 120%;
  animation: gradient-animation 2s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}